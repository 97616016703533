import { EventManage } from '../components/Event/Manager';

function EventsEditRoute() {
  return (
    <EventManage
      withAdminHeader={false}
      canceledMessage=''
      home='/admin/toolkit/events/list'
      className=''
    />
  );
}

export const Component = EventsEditRoute;

export function clientLoader() {
  return null;
}
